import { addFavorite } from '../addFavorite';
import { removeFavorite } from '@/shared/api/favorites/removeFavorite';

type Params = {
  id: number;
  type: 'add' | 'remove';
  lang: string;
};

export const toggleFavorite = ({ id, type, lang }: Params) => {
  switch (type) {
    case 'add':
      return addFavorite({ photo_id: id, lang });
    case 'remove':
      return removeFavorite({ photo_id: id, lang });
  }
};
