import { useInfiniteQuery } from '@tanstack/react-query';
import { getFavorites } from '@/shared/api/favorites/getFavorites';

type Params = Parameters<typeof getFavorites>[0];

interface Options {
  enabled?: boolean;
}

export const useGetFavorites = (params: Params, options?: Options) => {
  return useInfiniteQuery({
    queryFn: ({ pageParam }) => getFavorites({ ...params, offset: pageParam }),
    queryKey: getUseGetFavoritesQueryKey(params),
    initialPageParam: params.offset ?? 0,
    getNextPageParam: (lastPage, allPages) =>
      lastPage?.length ? allPages.flat().length : null,
    getPreviousPageParam: () => null,
    gcTime: 0,
    ...options,
  });
};

export const getUseGetFavoritesQueryKey = (params: Params) => [
  'getFavorites',
  params,
];
