'use client';
import { FC, MouseEvent, ReactNode } from 'react';
import Button from '@/shared/ui/Button/Button';
import FavoritesIcon from '~/icons/favorites.svg';
import FavoritesFillIcon from '~/icons/favorites-fill.svg';
import { useToggleFavorite } from '@/shared/hooks/useToggleFavorite';
import { useGetFavorites } from '@/shared/hooks/useGetFavorites';
import { handleRequireAuth } from '@/shared/utils/handleRequireAuth';
import { useLocalizedPathname } from '@/shared/i18n/routing';
import useQueryParam from '@/shared/hooks/useQueryParam';
import { useLocale } from 'next-intl';
import { cva } from 'class-variance-authority';
import clsx from 'clsx';

interface Props {
  children?: ReactNode;
  isIconAfter?: boolean;
  onlyIcon?: boolean;
  className?: string;
  initialIsFavorite?: boolean;
  imageId: number;
  initialCount?: number;
}

const FavoritesButton: FC<Props> = ({
  children,
  className,
  initialCount = 0,
  initialIsFavorite = false,
  isIconAfter,
  onlyIcon,
  imageId,
}) => {
  const pathname = useLocalizedPathname();
  const locale = useLocale();

  const { isFavorite, count, isPending, onToggle } = useToggleFavorite({
    initialIsFavorite,
    initialCount,
    photoId: imageId,
  });

  const onClick = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    handleRequireAuth(onToggle);
  };

  /*
   * Этот запрос должен быть всегда с enabled: false.
   * Нужен для того, чтобы задизейблить кнопку во время
   * получения избранных постранично, дабы избежать состояния гонки
   * */
  const [category] = useQueryParam<string | undefined>('category');
  const { isFetching: isGetFavoritesFetching } = useGetFavorites(
    {
      category_id:
        pathname === '/favorites' && category
          ? Number.parseInt(category)
          : undefined,
      offset: 0,
      limit: 12,
      lang: locale,
    },
    {
      enabled: false,
    }
  );

  return (
    <Button
      className={clsx(cvaRoot(), className)}
      size={'xs'}
      theme={'blurred'}
      disabled={isPending || isGetFavoritesFetching}
      onClick={onClick}
      isIconAfter={isIconAfter}
      onlyIcon={onlyIcon}
      icon={
        isFavorite ? (
          <FavoritesFillIcon className={'w-2.4 h-2.4 fill-cPurple500'} />
        ) : (
          <FavoritesIcon className={'w-2.4 h-2.4'} />
        )
      }>
      {children}
      {onlyIcon ? null : count}
    </Button>
  );
};

const cvaRoot = cva(['FavoritesButton-cvaRoot', 'flex-shrink-0 grow-0']);

export default FavoritesButton;
