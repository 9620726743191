import { API } from '../../api';
import { axiosInstance } from '../../axios';
import { IImage } from '../../types/image';

interface Params {
  limit?: number;
  offset?: number;
  category_id: number | undefined;
  lang: string;
}

export const getFavorites = async ({
  limit = 12,
  offset = 0,
  ...params
}: Params) => {
  const result = await axiosInstance.get<IImage[]>(API.getFavorites, {
    params: { limit, offset, ...params },
  });

  return result.data;
};
