import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';

interface FavoritePhoto {
  photoId: number;
  count: number;
  isActive: boolean;
}

interface InitialState {
  data: FavoritePhoto[];
  update: (data: FavoritePhoto) => void;
  add: (data: FavoritePhoto) => void;
  remove: (data: FavoritePhoto) => void;
}

export const useFavoritesStore = create<InitialState>()(
  immer((set) => ({
    data: [] as FavoritePhoto[],
    add: (data) =>
      set((state) => {
        state.data.push(data);
      }),
    update: (data) =>
      set((state) => {
        const favoritePhoto = state.data.find(
          (i) => i.photoId === data.photoId
        );

        if (favoritePhoto) {
          favoritePhoto.count = data.count;
          favoritePhoto.isActive = data.isActive;
        }
      }),
    remove: (data) =>
      set((state) => {
        state.data = state.data.filter((i) => i.photoId !== data.photoId);
      }),
  }))
);
