import { API } from '../../api';
import { axiosInstance } from '../../axios';
import { HEADERS } from '../../headers';

type Params = {
  photo_id: number;
  lang: string;
};

export const removeFavorite = async ({ lang, ...body }: Params) => {
  const result = await axiosInstance.post(API.removeFavorite, body, {
    params: { lang },
    headers: {
      ...HEADERS.contentTypeUrlencoded,
    },
  });

  return result.data;
};
