import qs from 'qs';
import { API } from '../../api';
import { axiosInstance } from '../../axios';
import { HEADERS } from '../../headers';

interface Params {
  photo_id: number;
  lang: string;
}

export const addFavorite = async ({ lang, ...body }: Params) => {
  const result = await axiosInstance.post(API.addFavorite, qs.stringify(body), {
    params: { lang },
    headers: {
      ...HEADERS.contentTypeUrlencoded,
    },
  });

  return result.data;
};
