import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toggleFavorite } from '@/shared/api/favorites/toggleFavorite';
import { useFavoritesStore } from '@/shared/store/favoritesStore';
import { IImage } from '@/shared/api/types/image';
import {
  getUseGetFavoriteCategoriesQueryKey,
  useGetFavoriteCategories,
} from '@/shared/hooks/useGetFavoriteCategories';
import { useLocalizedPathname } from '@/shared/i18n/routing';
import { useLocale } from 'next-intl';
import { getUseGetFavoritesQueryKey } from '@/shared/hooks/useGetFavorites';

interface Params {
  initialCount?: number;
  initialIsFavorite?: boolean;
  photoId: number;
}

export const useToggleFavorite = ({
  initialIsFavorite = false,
  initialCount = 0,
  photoId,
}: Params) => {
  const pathname = useLocalizedPathname();
  const locale = useLocale();

  const { data: favoriteCategories } = useGetFavoriteCategories(
    {
      lang: locale,
    },
    {
      enabled: pathname === '/favorites',
    }
  );

  const favorite = useFavoritesStore((state) =>
    state.data.find((i) => i.photoId === photoId)
  );

  const { add, update } = useFavoritesStore();

  const mutation = useMutation({
    mutationFn: toggleFavorite,
    mutationKey: getUseToggleFavoriteMutationKey(),
  });

  const queryClient = useQueryClient();

  const onToggle = () => {
    const type = favorite?.isActive ?? initialIsFavorite ? 'remove' : 'add';

    mutation.mutate(
      { type, id: photoId, lang: locale },
      {
        onSuccess: () => {
          void queryClient.invalidateQueries({
            queryKey: getUseGetFavoriteCategoriesQueryKey(),
          });

          switch (type) {
            case 'add': {
              favorite
                ? update({ photoId, count: favorite.count + 1, isActive: true })
                : add({ photoId, count: initialCount + 1, isActive: true });

              break;
            }

            case 'remove': {
              favorite
                ? update({
                    photoId,
                    count: favorite.count - 1,
                    isActive: false,
                  })
                : add({ photoId, count: initialCount - 1, isActive: false });

              if (pathname === '/favorites') {
                queryClient.setQueryData<{ pages: IImage[][] }>(
                  getUseGetFavoritesQueryKey({
                    category_id: undefined,
                    lang: locale,
                  }),
                  (prev) => {
                    if (!prev) return prev;

                    return {
                      ...prev,
                      pages: prev.pages.map((i) =>
                        i.filter((i) => i.photoId !== photoId)
                      ),
                    };
                  }
                );

                favoriteCategories?.forEach((i) => {
                  queryClient.setQueryData<{ pages: IImage[][] }>(
                    getUseGetFavoritesQueryKey({
                      category_id: i.category_id,
                      lang: locale,
                    }),
                    (prev) => {
                      if (!prev) return prev;

                      return {
                        ...prev,
                        pages: prev.pages.map((i) =>
                          i.filter((i) => i.photoId !== photoId)
                        ),
                      };
                    }
                  );
                });
              }
            }
          }
        },
      }
    );
  };

  return {
    onToggle,
    isFavorite: favorite?.isActive ?? initialIsFavorite,
    count: favorite?.count ?? initialCount,
    isPending: mutation.isPending,
  };
};

export const getUseToggleFavoriteMutationKey = () => ['toggleFavorite'];
