import { useQuery } from '@tanstack/react-query';
import { getFavoriteCategories } from '@/shared/api/favorites/getFavoriteCategories';

type Params = Parameters<typeof getFavoriteCategories>[0];

interface Options {
  enabled?: boolean;
}

export const useGetFavoriteCategories = (params: Params, options?: Options) => {
  return useQuery({
    queryKey: getUseGetFavoriteCategoriesQueryKey(params),
    queryFn: () => getFavoriteCategories(params),
    select: (data) => data?.result ?? [],
    ...options,
  });
};

export const getUseGetFavoriteCategoriesQueryKey = (params?: Params) =>
  ['getFavoriteCategories', params].filter(Boolean);
