import { API } from '../../api';
import { axiosInstance } from '../../axios';
import { IFavoriteCategory } from '@/shared/api/favorites/getFavoriteCategories/types';

interface Params {
  lang: string;
}

export const getFavoriteCategories = async (params: Params) => {
  const result = await axiosInstance.get<{ result: IFavoriteCategory[] }>(
    API.getFavoriteCategories,
    { params }
  );

  return result.data;
};
